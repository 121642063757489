import axios from 'axios';

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL_BASE
});

Axios.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => Promise.reject(error)
);

Axios.interceptors.response.use(
  response => {
    // any interceptor for resolve response
    return Promise.resolve(response);
  },
  error => {
    // any interceptor for error messages
    return Promise.reject(error);
  }
);

export default Axios;
