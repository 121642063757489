import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  getDocumentoByTipoAndCc: (idCentroCosto) => {
    return new Promise((resolve, reject) => {
      auth({
        url: BASE_URL + 'documento/getFileByTipoAndCc?idCentroCosto='+idCentroCosto+'&tipo=1',
        method: 'GET',
        responseType: 'blob', // Important
      })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  downloadDocumentoProject: (id) => {
    return new Promise((resolve, reject) => {
      auth({
        url: BASE_URL + 'documento/getFileByTipoAndProyecto?idProyecto='+id+'&tipo=2',
        method: 'GET',
        // responseType: 'blob', // Important
      })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  uploadDocumentoProject: (idCentroCosto, id, file) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file.document);
    return new Promise((resolve, reject) => {
      auth({
        url: BASE_URL + 'documento/createDocProyecto?idCentroCosto='+idCentroCosto+'&idProyecto='+id+'&nombre=Aprobacion&tipo=2',
        method: 'POST',
        data: bodyFormData,
      })
        .then(() => {
          auth.put(BASE_URL + 'proyecto/updateAprobacion?aprueba=true&id='+id, {})
            .then(() => {
                auth
                  .get(BASE_URL + 'proyecto/getById?id=' + id)
                  .then(response => (resolve(response)))
                  .catch(error => (reject(error)));
            })
            .catch(error => (reject(error)));
        })
        .catch(error => (reject(error)));
    });
  },
  uploadDocumentoProjectReiterate: (idCentroCosto, id, file) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file.document);
    return new Promise((resolve, reject) => {
      auth({
        url: BASE_URL + 'reitemizacion/createDocProyectoReiterate?idCentroCosto='+idCentroCosto+'&idProyecto='+id,
        method: 'POST',
        data: bodyFormData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => (reject(error)));
    });
  },
  getAllBySubcategory: (subcategory) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'documento/getAllBySubcategoriaTipoCc?idCentroCosto=119&subcategoria='+subcategory+'&tipo=3')
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  downloadDocumentoById: (id) => {
    return new Promise((resolve, reject) => {
      auth({
        url: BASE_URL + 'documento/getFileById?id='+id,
        method: 'GET',
        responseType: 'blob', // Important
      })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
};