import axios from '@/utils/axios';
import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  login: (username, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL + 'user/login', {
          username: username,
          password: password
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },

  validate: token => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'user/validate', {
          token: token,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
