import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    create: (evaluation) => {
        return new Promise((resolve, reject) => {
            auth
                .post(BASE_URL + 'evaluacion', evaluation)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getAll: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'evaluacion')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    update: (id, evaluation) => {
    return new Promise((resolve, reject) => {
        auth
            .put(BASE_URL + 'evaluacion/' + id,
                evaluation
            )
            .then(response => (resolve(response)))
            .catch(error => (reject(error)));
    });
    },
    getForPeriod: (period) => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'evaluacion/periodo/'+period)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },

}