import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  getByTipoYMayor: (data, type) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'centrocosto/getByTipoYMayor?esMayor=' + data + '&tipo=' + type)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  getAllCentroCostos: () => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'centrocosto/getAllByAcademico')
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  getAll: () => {
    return new Promise((resolve, reject) => {
      auth
          .get(BASE_URL + 'centrocosto')
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
  // getMenoresByccMayor: ccValor => {
  //   return new Promise((resolve, reject) => {
  //     auth
  //       .get(BASE_URL + 'centrocosto/getMenoresByccMayor?ccValor=' + ccValor)
  //       .then(response => (resolve(response)))
  //       .catch(error => (reject(error)));
  //   });
  // },
  getMenoresByIdMayor: ccId => {
    return new Promise((resolve, reject) => {
      auth
          .get(BASE_URL + 'centrocosto/getMenoresByIdMayor?ccId=' + ccId)
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
  getExcelCC: () => {
    return new Promise((resolve, reject) => {
      auth({
        url: BASE_URL + 'centrocosto/exportToExcel',
        method: 'GET',
        responseType: 'blob', // Important
      })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  updateLeido: ccValor => {
    return new Promise((resolve, reject) => {
      auth
        .put(BASE_URL + 'centrocosto/updateLeido?ccValor=' + ccValor + '&leido=true')
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
    getAllMayor() {
      return new Promise((resolve, reject) => {
        auth
            .get(BASE_URL + 'centrocosto/mayor')
            .then(response => (resolve(response)))
            .catch(error => (reject(error)));
      });
    },
  create: (data) => {
    return new Promise((resolve, reject) => {
      auth
          .post(BASE_URL + "centrocosto", data)
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },edit: (data) => {
    return new Promise((resolve, reject) => {
      auth
          .put(BASE_URL + "centrocosto/" + data.id, data)
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
  getAllActivo: () => {
    return new Promise((resolve, reject) => {
      auth
          .get(BASE_URL + 'centrocosto/activo')
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },

};
