import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  refreshToken: email => {
    return new Promise((resolve, reject) => {
      auth
        .post(BASE_URL + 'user/refreshToken', {
          correo: email
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  updateCC: (email,ccValor) => {
    return new Promise((resolve, reject) => {
      auth
        .post(BASE_URL + 'user/cambiarCC', {
          correo: email,
          ccValor: ccValor
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  changePassword: (email, password) => {
    return new Promise((resolve, reject) => {
      auth
        .post(BASE_URL + 'user/changePassword', {
          email: email,
          password: password
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  forgetPassword: (email) => {
    return new Promise((resolve, reject) => {
      auth
        .post(BASE_URL + 'user/forgetPassword?correo=', {
          correo: email
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  loginProvisorio: (email, code, password, passwordConfirm) => {
    return new Promise((resolve, reject) => {
      auth
        .post(BASE_URL + 'user/loginProvisorio', {
          correo: email,
          provisoria: code,
          passNueva: password,
          passNuevaConfirmacion: passwordConfirm
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
    getPeriodActive: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'user/period/active')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    setPeriodActive: (period) => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'user/period/'+period+'/active', {})
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    listUsers: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'user/')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    storeUsers: data => {
        return new Promise((resolve, reject) => {
            auth
                .post(BASE_URL + 'user/', data)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    deleteUsers: id => {
        return new Promise((resolve, reject) => {
            auth
                .delete(BASE_URL + 'user/'+id)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    resetPasswordUsers: id => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'user/' + id + '/reset-password')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    updateUsers: data => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'user/' + data.id, data)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
};
