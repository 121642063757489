import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  getHitoByIdProyecto: (id) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'hito/getByIdProyecto?idProyecto=' + id)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  deleteHito: (id) => {
    return new Promise((resolve, reject) => {
      auth
        .delete(BASE_URL + 'hito/delete?id=' + id)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  createHito: (id,hito) => {
    return new Promise((resolve, reject) => {
      auth
        .post(BASE_URL + 'hito/create?idProyecto=' + id,
        {
          actividades: hito.actividades,
          mesInicio: hito.mesInicio,
          mesTermino: hito.mesTermino,
          nombreHito: hito.nombreHito,
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  updateHito: (id,hito) => {
    return new Promise((resolve, reject) => {
      auth
        .put(BASE_URL + 'hito/update',
        {
          id: id,
          actividades: hito.actividades,
          mesInicio: hito.mesInicio,
          mesTermino: hito.mesTermino,
          nombreHito: hito.nombreHito,
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
    updatePorcentajeAvanceHito: (id, percent)=>{
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'hito/set-percentage-completion',
                    {
                        id: id,
                        porcentajeAvanceHito: percent,
                    })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
},
    updateObservacionesHito: (id, percent)=>{
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'hito/set-observation',
                    {
                        id: id,
                        observacionesHito: percent,
                    })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
  }
};
