import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    getAll: () => {
        return new Promise((resolve, reject) => {
            auth
            .get(BASE_URL + 'estrategia/v2')
            .then(response => (resolve(response)))
            .catch(error => (reject(error)));
        });
    },
    create: (data) => {
        return new Promise((resolve, reject) => {
            auth
                .post(BASE_URL + 'estrategia/v2', data)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    update: (id, data) => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'estrategia/v2/' + id, data)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    }
}