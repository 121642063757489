import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  getTotalItemGasto: (ccValor) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'itemGasto/getTotalCC?ccValor=' + ccValor)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  getTotalItemGastoProyecto: (id) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'itemGasto/getTotalProyecto?idProyecto=' + id)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  getItemGastoByProyecto: (idProject) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'itemGasto/getByIdProyecto?idProyecto=' + idProject)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  createItemGasto: (idProject, itemGasto) => {
    return new Promise((resolve, reject) => {
      auth
        .post(BASE_URL + 'itemGasto/create?idProyecto=' + idProject,
        {
          descripcionItem: itemGasto.descripcion,
          montoItem: parseInt(itemGasto.monto),
          nombreItem: itemGasto.nombre
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  updateItemGasto: (id, itemGasto) => {
    return new Promise((resolve, reject) => {
      auth
        .put(BASE_URL + 'itemGasto/update',
        {
          id: id,
          descripcionItem: itemGasto.descripcion,
          montoItem: parseInt(itemGasto.monto),
          nombreItem: itemGasto.nombre
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  deleteItemGasto: (id) => {
    return new Promise((resolve, reject) => {
      auth
        .delete(BASE_URL + 'itemGasto/delete?id=' + id)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
};
