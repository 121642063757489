import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    create: (initiative) => {
        return new Promise((resolve, reject) => {
            auth
                .post(BASE_URL + 'iniciativa', initiative)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getNotFinishedAll: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'iniciativa/not-finished')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getFinishedAll: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'iniciativa/finished')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    update: (id, data) => {
    return new Promise((resolve, reject) => {
        auth
            .put(BASE_URL + 'iniciativa/' + id,
                data
            )
            .then(response => (resolve(response)))
            .catch(error => (reject(error)));
        });
    },
    updatePercentageProgress: (data) => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'iniciativa/' + data.id + '/percentage-progress',
                    data
                )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    updateProgressFeedback: (data) => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'iniciativa/' + data.id + '/progress-feedback',
                    data
                )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    updateProgress: (data) => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'iniciativa/' + data.id + '/progress',
                    data
                )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    finish: (data) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('file', data.file);
            auth
                .put(BASE_URL + 'iniciativa/' + data.id + '/finish-iniciativa',
                    bodyFormData
                )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    showFile(id) {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'iniciativa/finished/'+ id +'/file')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    }
}