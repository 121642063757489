import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  getAllByIdAreaMisional: id => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'estrategia/getAllByIdAreaMisional?idAreaMisional=' + id)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
createEstrategia: (estrategia) => {
  return new Promise((resolve, reject) => {
    auth
        .post(BASE_URL + 'estrategia/v2', estrategia)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
  });
},
    getAll: () => {
  return new Promise((resolve, reject) => {
    auth
        .get(BASE_URL + 'estrategia/v2')
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
  });
},
    update: (id,data) => {
  return new Promise((resolve, reject) => {
    auth
        .put(BASE_URL + 'estrategia/v2/' + id,
            data
        )
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
  });
},

    getAllAreasMisionalesByEje: (idEje) => {
  return new Promise((resolve, reject) => {
    auth
        .get(BASE_URL + 'estrategia/v2/'+idEje+'/areas-misionales')
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
  });
},
    getAllEjeEstrategicosByTransversal: (data) => {
  return new Promise((resolve, reject) => {
    auth
        .get(BASE_URL + 'estrategia/v2/eje-estrategico/'+data.eje+'/area-misional/'+data.misional)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
  });
},
};
