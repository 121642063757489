import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('@/views/auth/Login'),
      meta: {
        auth: false,
      },
    },
    {
      name: 'logout',
      path: '/logout',
      component: () => import('@/views/auth/Logout'),
      meta: {
        auth: true,
      },
    },
    {
      name: 'change-password',
      path: '/change-password',
      component: () => import('@/views/auth/Password'),
      meta: {
        auth: true,
      },
    },
    {
      name: 'new-password',
      path: '/new-password',
      component: () => import('@/views/auth/NewPassword'),
      meta: {
        auth: false,
      },
    },
    {
      name: 'forgot',
      path: '/forgot',
      component: () => import('@/views/auth/Forgot'),
      meta: {
        auth: false,
      },
    },
    {
      name: 'home',
      path: '/home',
      component: () => import('@/views/orchestrator/OrchestratorInitialView.vue'),
      meta: {
        auth: true,
      },
    },
    {
      name: 'admin',
      path: '/admin',
      component: () => import('@/views/admin/Home'),
      meta: {
        auth: true,
      },
    },
    {
      name: 'bases-administrativas',
      path: '/bases-administrativas',
      component: () => import('@/views/stages/AdministrativeBasesView.vue'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'strategic-planning',
      path: '/strategic-planning',
      component: () => import('@/components/Informations/StrategicPlanning.vue'),
      meta: {
        auth: true
      }
    },
    {
      name: 'strategic-diagnostics',
      path: '/strategic-diagnostics',
      component: () => import('@/components/Informations/StrategicDiagnostics.vue'),
      meta: {
        auth: true
      }
    },
    {
      name: 'formularios-ejecucion',
      path: '/formularios-ejecucion',
      component: () => import('@/views/stages/ExecutionFormsView.vue'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'reiterate',
      path: '/formularios-ejecucion/reiterate',
      component: () => import('@/views/stages/DownloadForms/Reiterate'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'ficha-plan-operativo',
      path: '/ficha-plan-operativo',
      component: () => import('@/views/stages/OperationalPlanView.vue'),
      meta: {
        auth: true
      }
    },
    {
      name: 'proyecto-crear',
      path: '/ficha-plan-operativo/proyecto/crear',
      component: () => import('@/components/Project/ProjectCreate.vue'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'proyecto-editar',
      path: '/ficha-plan-operativo/proyecto/editar/:id',
      component: () => import('@/components/Project/ProjectEdit.vue'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'proyecto-hitos',
      path: '/ficha-plan-operativo/proyecto/:id/hitos/crear',
      component: () => import('@/components/Milestones/Milestones.vue'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'proyecto-gastos',
      path: '/ficha-plan-operativo/proyecto/:id/gastos/crear',
      component: () => import('@/components/Expenses/Expense.vue'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'proyecto-montos',
      path: '/proyectos/montos/subir',
      component: () => import('@/views/TemplateAdvanceAmountsRequestedView.vue'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'seguimiento-evaluacion',
      path: '/seguimiento-evaluacion',
      component: () => import('@/views/stages/TracingAndEvaluationView'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'unidades-academicas',
      path: '/unidades-academicas',
      component: () => import('@/views/orchestrator/AcademicianHome'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'informaciones-pei2030',
      path: '/informaciones-pei2030',
      component: () => import('@/views/Informations.vue'),
      meta: {
        auth: true,
      }
    },
    {
      name: 'gobierno-central',
      path: '/gobierno-central',
      component: () => import('@/views/orchestrator/CentralGovernment'),
      meta: {
        auth: true,
      }
    },
    // =========================================
    // ERRORS
    // =========================================
    {
      name: '404',
      path: '*',
      component: () => import('@/views/error/404'),
      meta: {
        // tags for router
        both: true,
      },
    },
  ],
});

var redirectPassword = (firstTime, next, route = null) => {
  if (firstTime) {
    next({ name: 'change-password' });
  } else {
    if (route) {
      next(route);
    } else {
      next();
    }
  }
}

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.both)){
    next();
  } else {
    if (to.matched.some(record => record.meta.auth)) {
      // View require authentication -> | (auth user) => next | (not auth user) => redirect to login |
      let token = store.getters['authentication/token'];
      let decoded = store.getters['authentication/fullTokenDecoded'];
      store.dispatch('authentication/validate', token)
        .then(() => {
          next()
          if (decoded && decoded.firstTime != undefined) {
            redirectPassword(decoded.firstTime, next);
          }
        })
        .catch(err => {
          console.log("error: " + ((err.response && err.response.data && err.response.data.message) ? err.response.data.message : '') + ' (redirecting)');
          next({ name: 'login' });
        });
    } else {
      // View does not require authentication -> | (auth user) => redirect to home | (not auth user) => next |
      let token = store.getters['authentication/token'];
      let decoded = store.getters['authentication/fullTokenDecoded'];
      store.dispatch('authentication/validate', token)
        .then(() => {
          console.log('error: Token found (redirecting)');
          if (decoded && decoded.firstTime != undefined) {
            redirectPassword(decoded.firstTime, next, { name: 'home' });
          }
        })
        .catch(err => {
          console.log("sucess: " + ((err.response && err.response.data && err.response.data.message) ? err.response.data.message : '') + ' (next)');
          next();
        });
    }
  }
 
});

export default router;