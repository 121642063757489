import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    delete: (id) => {
        return new Promise((resolve, reject) => {
            auth
                .delete(BASE_URL + 'transversal-eje-estrategico/'+id)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    }
}