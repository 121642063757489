import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  uploadDocumentoProjectReiterate: (idCentroCosto, id, file) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file.document);
    return new Promise((resolve, reject) => {
      auth({
        url: BASE_URL + 'reitemizacion/createDocProyectoReiterate?idCentroCosto='+idCentroCosto+'&idProyecto='+id,
        method: 'POST',
        data: bodyFormData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => (reject(error)));
    });
  },
  getAllReitemizacionByProyecto: (id) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'reitemizacion/getAllByProyecto?idProyecto='+id)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  downloadReitemizacionById: (id) => {
    return new Promise((resolve, reject) => {
      auth({
        url: BASE_URL + 'reitemizacion/getFileById?id='+id,
        method: 'GET',
        responseType: 'blob', // Important
      })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
};