import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    create: (question) => {
        return new Promise((resolve, reject) => {
            auth
                .post(BASE_URL + 'pregunta', question)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getAll: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'pregunta')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    update: (id, question) => {
    return new Promise((resolve, reject) => {
        auth
            .put(BASE_URL + 'pregunta/' + id,
                question
            )
            .then(response => (resolve(response)))
            .catch(error => (reject(error)));
    });
    }
}