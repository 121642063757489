import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    save: (data)=>{
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('period', data.period);
            bodyFormData.append('file', data.file);
            bodyFormData.append('pages', data.pages);
            auth.post(BASE_URL + 'administrative-bases', bodyFormData )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    list: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'administrative-bases')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    showFile: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'administrative-bases/file')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    showFileById: (id) => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'administrative-bases/'+id)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    delete: (id) => {
        return new Promise((resolve, reject) => {
            auth
                .delete(BASE_URL + 'administrative-bases/'+id)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
}