<template>
  <v-app>
    <div class="main-component main-color main-text--text">
      <transition name="fade" mode="out-in">
        <loading-component v-if="loading" />
      </transition>
      <!-- AUTH USER -->
      <v-container fluid class="ma-0 pa-0 main-container" v-if="!loading && auth">
        <v-navigation-drawer class="app-drawer" v-model="sidebar">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                <v-img
                  width="100px"
                  :src="require('@/assets/navbar.png')"
                ></v-img>
              </v-list-item-title>
              <v-list-item-subtitle v-if="subAppTitle">
                {{ subAppTitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list
            dense
            nav
          >
            <v-list-item
              v-for="item in menuItems.filter(e => e.show)"
              :key="item.title"
              :to="item.path"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- <template v-slot:append>
            <div class="pa-2">
              <v-btn color="primary-color" class="primary-text--text" block>
                Logout
              </v-btn>
            </div>
          </template> -->
        </v-navigation-drawer>

        <v-app-bar dark color="d-print-none primary-color" :hide-on-scroll="$route.name == 'welcome'" app elevation="1">
          <span class="hidden-sm-and-up">
            <v-app-bar-nav-icon @click="sidebar = !sidebar">
            </v-app-bar-nav-icon>
          </span>
          <v-toolbar-title class="hidden-xs-only">
            <router-link to="/" tag="span" style="cursor: pointer">
              <v-row class="ma-0 pa-0" align="center">
                <v-img
                  width="100px"
                  :src="require('@/assets/navbar.png')"
                ></v-img>
              </v-row>
            </router-link>
          </v-toolbar-title>

          <div class="divider"></div>
          <v-toolbar-items class="hidden-xs-only">
            <div class="text-center pt-5 ml-3">
              <h4><b>Periodo Activo: {{periodChanged}}</b></h4>
            </div>
          </v-toolbar-items>
          <div class="flex-grow-1"></div>
          <v-toolbar-items class="hidden-xs-only">
            <v-col class="px-1 pa-0 ma-0" align-self="center">
              <div class="text-center">
                <v-dialog v-model="dialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip class="success" v-bind="attrs" v-on="on">
                      <v-icon small class="mr-1">mdi-calendar</v-icon>
                      <span class="mr-1">Cambiar Periodo</span>
                    </v-chip>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Seleccione Periodo
                    </v-card-title>
                    <v-card-text>
                      <v-select
                          v-model="periodSelected"
                          :items="periods"
                          :item-text="'name'"
                          :item-value="'id'"
                          label="Seleccione Periodo"
                      ></v-select>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                          color="primary"
                          text
                          @click="dialog = false"
                      >
                        Cerrar
                      </v-btn>

                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary"
                          text
                          @click="setItemPeriodActive()"
                      >
                        Actualizar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>

            <v-col class="px-0 pa-0 ma-0" align-self="center">
              <v-chip v-if="info.analista"
                color="secondary-color">
                <span class="mr-1"><strong>Analista :</strong></span> {{ info.analista.name }} - {{info.analista.email}}
              </v-chip>
            </v-col>

<!--            <v-col class="px-1 pa-0 ma-0" align-self="center">-->
<!--              <v-chip-->
<!--                close-->
<!--                close-icon="mdi-account-circle"-->
<!--                color="secondary-color"-->
<!--              >-->
<!--                {{ fullTokenDecoded.nombre }}-->
<!--              </v-chip>-->
<!--            </v-col>-->

            <v-btn
              text
              small
              elevation="0"
              class="px-4"
              v-for="item in menuItems.filter(e => e.show)"
              :key="item.title"
              :to="item.path">
              <v-icon small left dark>{{ item.icon }}</v-icon>
              {{ item.title }}
            </v-btn>
            <template>
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
<!--                    <v-btn-->
<!--                        color="indigo"-->
<!--                        dark-->
<!--                        v-bind="attrs"-->
<!--                        v-on="on"-->
<!--                    >-->
<!--                      Menu as Popover-->
<!--                    </v-btn>-->
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        small
                        elevation="0"
                        class="">
                      <v-icon small left dark>mdi-account-circle</v-icon>
                      Perfil
                    </v-btn>
                  </template>

                  <v-card max-width="700px">
                    <v-row>
                      <v-col cols="6"><v-list>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon x-large>mdi-account-circle</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="mb-1">{{ fullTokenDecoded.nombre }}</v-list-item-title>
                            <v-list-item-subtitle class="mb-2">{{ fullTokenDecoded.sub }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="mb-2">{{ info.ccNombre }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="mb-2">Area {{ info.tipoUnidad }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list></v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                          text
                          @click="menu = false">
                        Cerrar
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          to="/logout">
                        <v-icon small dark>mdi-logout</v-icon>Salir
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
            </template>
          </v-toolbar-items>
        </v-app-bar>

        <div class="main-app-color">
          <router-view></router-view>
        </div>

        <v-footer
          dark
          padless
          class="d-print-none"
        >
          <v-card
            flat
            tile
            class="w-full secondary-color lighten-1 white--text text-center"
          >
            <v-card-text v-if="footerText" class="w-full white--text pt-0">
              {{ footerText }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
              <v-row justify="center">
                Gestión 2030
              </v-row>
              <v-row justify="center">
                Prorrectoría
              </v-row>
              <v-row justify="center">
                Universidad de Santiago de Chile | Avenida las Sophoras #135 | Estación Central - Chile
              </v-row>
            </v-card-text>
          </v-card>
        </v-footer>
      </v-container>
      <!-- NOT AUTH USER -->
      <v-container fluid class="ma-0 pa-0 main-container" v-if="!loading && !auth">

        <v-navigation-drawer class="app-drawer" v-model="sidebar">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                <v-img
                  width="100px"
                  :src="require('@/assets/navbar.png')"
                ></v-img>
              </v-list-item-title>
              <v-list-item-subtitle v-if="subAppTitle">
                {{ subAppTitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list
            dense
            nav
          >
            <v-list-item
              v-for="item in menuItems.filter(e => e.show)"
              :key="item.title"
              :to="item.path"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <template v-slot:append>
            <div class="pa-2">
              <v-btn color="primary-color" class="primary-text--text" block>
                Logout
              </v-btn>
            </div>
          </template>
        </v-navigation-drawer>

        <v-app-bar dark color="primary-color" :hide-on-scroll="$route.name == 'welcome'" app elevation="1">
          <span class="hidden-sm-and-up">
            <v-app-bar-nav-icon @click="sidebar = !sidebar">
            </v-app-bar-nav-icon>
          </span>
          <v-toolbar-title class="hidden-xs-only">
            <router-link to="/" tag="span" style="cursor: pointer">
              <v-row class="ma-0 pa-0" align="center">
                <v-img
                  width="100px"
                  :src="require('@/assets/navbar.png')"
                ></v-img>
              </v-row>
            </router-link>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-xs-only">
            <v-btn
              text
              small
              elevation="0"
              class="px-4"
              v-for="item in menuItems.filter(e => e.show)"
              :key="item.title"
              :to="item.path">
              <v-icon small left dark>{{ item.icon }}</v-icon>
              {{ item.title }}
            </v-btn>
          </v-toolbar-items>
        </v-app-bar>
        <div class="full-page">
          <router-view></router-view>
        </div>
        <v-footer
          dark
          padless
        >
          <v-card
            flat
            tile
            class="w-full secondary-color lighten-1 white--text text-center"
          >
            <v-card-text v-if="footerText" class="white--text pt-0">
              {{ footerText }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
              <v-row justify="center">
                Gestión 2030
              </v-row>
              <v-row justify="center">
                Prorrectoría
              </v-row>
              <v-row justify="center">
                Universidad de Santiago de Chile | Avenida las Sophoras #135 | Estación Central - Chile
              </v-row>
            </v-card-text>
          </v-card>
        </v-footer>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '@/components/animations/Loading.vue';
import Swal from "sweetalert2";

export default {
  name: 'App',
  components: {
    'loading-component': Loading,
  },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      syncPeriod: true,
      periods: [],
      periodSelected: 0,
      periodChanged: 0,
      footerText: '',
      loading: true,
      auth: false,
      version: process.env.VUE_APP_VERSION,
      appTitle: process.env.VUE_APP_TITLE,
      subAppTitle: process.env.VUE_APP_SUBTITLE,
      sidebar: false,
      menuItems: this.routes(),
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      dialog: false
    };
  },
  async created() {
    console.log('Version ' + this.version);
    this.setPeriod();
    await this.validate(this.token)
      .then(() => (this.auth = true))
      .catch(() => (this.auth = false))
      .finally(() => (this.loading = false));
  },
  updated() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions('main', [
      'getPeriodActive',
      'setPeriodActive',
    ]),
    ...mapActions('authentication', ['validate']),

    setPeriod(){
      this.periods.push({id: 0, name: "Seleccione"})
      for (let i = 2021; i <= new Date().getFullYear(); i++){
        this.periods.push({id: i, name: i})
      }
    },
    getItemPeriodActive(){
      this.getPeriodActive().then(response => {

        this.periodChanged = response.data.response;
        localStorage.setItem('periodo',this.periodChanged);
      }).catch(e => {
        console.log(e);
      });
    },
    setItemPeriodActive(){
      console.log(this.periodSelected);
      if(this.periodSelected !== 0 && this.periodSelected != null) {
        this.setPeriodActive(this.periodSelected).then(response => {
          console.log(response);
          Swal.fire({
            title: 'Periodo',
            text: 'El periodo ha sido activado',
            confirmButtonColor: '#EA7600',
            confirmButtonText: 'Aceptar',
            icon: 'success',
          }).then(function(){
            window.location.reload();
          });
          this.dialog = false;
        }).catch(e => {
          console.log(e);
        });
      }else{
        Swal.fire({
          title: 'Periodo',
          text: 'El periodo no ha podido ser activado',
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
      }
    },
    routes() {
      let admin = false;
      let uci = false;
      if(this.fullTokenDecoded && this.fullTokenDecoded.ccComplete.id === 51){
        uci = true;
      }
      if (this.fullTokenDecoded && this.fullTokenDecoded.authorities) {
        admin = this.fullTokenDecoded.authorities.find(e => e === 'dge') !== undefined;
      }
      return [
        { title: 'info-pei2030', path: '/informaciones-pei2030', icon: 'mdi-folder-information', show: !!this.user},
        { title: 'UCI', path: 'proyectos/montos/subir', icon: 'mdi-chart-bar', show: uci},
        { title: 'Admin', path: '/admin', icon: 'mdi-lock', show: admin },
        { title: 'Inicio', path: '/home', icon: 'mdi-home', show: !!this.user },
        { title: 'Entrar', path: '/login', icon: 'mdi-account-circle', show: !this.user },
      ];
    }
  },
  watch: {
    token(prev, next) {
      if (prev != next) {
        this.validate(this.token)
          .then(() => (this.auth = true))
          .catch(() => (this.auth = false));
      }
    },
    user() {
      this.menuItems = this.routes();
      this.getItemPeriodActive();
    }
  },
  computed: {
    ...mapGetters({
      token: ['authentication/token'],
      user: ['authentication/authenticated'],
      info: ['authentication/info'],
      fullTokenDecoded: ['authentication/fullTokenDecoded']
    }),
  },
};
</script>

<style>
body {
  margin: 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.w-full {
  width: 100%;
}
.app-drawer {
  height: 100vh;
  z-index: 20;
  position: absolute;
  text-align: left;
}
.main-container {
  min-height: 100vh;
}
.main-component {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
}
.full-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
