import EjeEstrategicoService from '@/services/ejeEstrategicoService';
import CentroCostosService from '@/services/centroCostosService';
import AreaMisionalService from '@/services/areaMisionalService';
import EstrategiaService from '@/services/estrategiaService';
import ProyectoService from '@/services/proyectoService';
import ItemGastoService from '@/services/itemGastoService';
import HitoService from '@/services/hitoService';
import DocumentoService from '@/services/documentoService';
import ReitemizacionService from '@/services/reitemizacionService';
import UserService from '@/services/userService';
import estrategiaService from '@/services/estrategiaService';
import IndicadorService from "../../services/IndicadorService";
import annualReportService from "../../services/annualReportService";
import planTransversalService from "../../services/planTransversalService";
import initiativeService from "../../services/InitiativeService";
import evaluationService from "../../services/evaluationService";
import questionService from "../../services/QuestionService";
import questionAnswerService from "../../services/QuestionAnswerService";
import StrategyService from "../../services/StrategyService"
import administrativeBasesService from "../../services/administrativeBasesService"
import ReportService from "../../services/ReportService"
import MontoProyecto from "@/services/montoProyecto";
import AnalystService from "@/services/analystService";
import ejeEstrategicoPlanTranversalService from "@/services/EjeEstrategicoPlanTranversalService";
import PlatformFormsCategoryService from "@/services/PlatformFormsCategoryService";
import PlatformFormsService from "@/services/PlatformFormsService";
const state = {};

const getters = {};

const actions = {
  refreshToken: ({ commit }, email) => {
    commit;
    return new Promise((resolve, reject) => {
      UserService.refreshToken(email)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  updateCC: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      UserService.updateCC(data.email, data.ccValor)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  changePassword: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      UserService.changePassword(data.email, data.password)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  loginProvisorio: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      UserService.loginProvisorio(data.email, data.code, data.password, data.passwordConfirm)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  forgetPassword: ({ commit }, email) => {
    commit;
    return new Promise((resolve, reject) => {
      UserService.forgetPassword(email)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },

    listUsers: ({ commit }) => {
        commit;
        return new Promise((resolve, reject) => {
            UserService.listUsers()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data,
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },

    storeUsers: ({ commit }, data) => {
        commit;
        return new Promise((resolve, reject) => {
            UserService.storeUsers(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data,
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
    deleteUsers: ({ commit }, id) => {
        commit;
        return new Promise((resolve, reject) => {
            UserService.deleteUsers(id)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data,
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
    resetPasswordUsers: ({ commit }, id) => {
        commit;
        return new Promise((resolve, reject) => {
            UserService.resetPasswordUsers(id)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data,
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
    updateUsers: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
        UserService.updateUsers(data)
            .then(response => {
                resolve({
                    data: {
                        status: 'ok',
                        response: response.data,
                    }
                });
            })
            .catch(err => reject(err));
    });
},

  getAllByIdCentroCosto: ({ commit }, id) => {
    commit;
    return new Promise((resolve, reject) => {
      EjeEstrategicoService.getAllByIdCentroCosto(id)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getByTipoYMayor: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      CentroCostosService.getByTipoYMayor(data.data, data.type)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getAllCentroCostos: ({ commit }) => {
    commit;
    return new Promise((resolve, reject) => {
      CentroCostosService.getAllCentroCostos()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  // getMenoresByccMayor: ({ commit }, ccValor) => {
  //   commit;
  //   return new Promise((resolve, reject) => {
  //     CentroCostosService.getMenoresByccMayor(ccValor)
  //         .then(response => {
  //           resolve({
  //             data: {
  //               status: 'ok',
  //               response: response.data,
  //             }
  //           });
  //         })
  //         .catch(err => reject(err));
  //   });
  // },
    getMenoresByIdMayor: ({ commit }, ccId) => {
        commit;
        return new Promise((resolve, reject) => {
            CentroCostosService.getMenoresByIdMayor(ccId)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data,
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
  updateLeido: ({ commit }, ccValor) => {
    commit;
    return new Promise((resolve, reject) => {
      CentroCostosService.updateLeido(ccValor)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getExcelCC: ({ commit }) => {
    commit;
    return new Promise((resolve, reject) => {
      CentroCostosService.getExcelCC()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getAllByIdEjeEstrategico: ({ commit }, id) => {
    commit;
    return new Promise((resolve, reject) => {
      AreaMisionalService.getAllByIdEjeEstrategico(id)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getAllByIdAreaMisional: ({ commit }, id) => {
    commit;
    return new Promise((resolve, reject) => {
      EstrategiaService.getAllByIdAreaMisional(id)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  // Proyectos
  createProyecto: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      ProyectoService.createProyecto(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err =>  reject(err));
    });
  },
  updateProyecto: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      ProyectoService.updateProyecto(data)
          .then(() => {
            ProyectoService.updateProyecto(data)
                .then(() => {
                  resolve({
                    data: {
                      status: 'ok',
                      response: 'ok',
                    }
                  });
                })
                .catch(err => reject(err));
          })
          .catch(err => reject(err));
    });
  },
  getAllProyecto: ({commit}, ccvalor) => {
    commit;
    return new Promise((resolve, reject) => {
      ProyectoService.getAll(ccvalor)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    });
  },
    deleteProject({commit}, id) {
      commit;
        return new Promise((resolve, reject) => {
            ProyectoService.delete(id)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
    getAllProjectAmountRequest: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            ProyectoService.getAllProjectsAmountRequest()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
  getObservacionProyecto: ({commit}, idProject) => {
    commit;
    return new Promise((resolve, reject) => {
      ProyectoService.findObservacionProyecto(idProject)
          .then(response => {
            if (response.data.length != 0) {
              resolve({
                data: {
                  status: 'ok',
                  response: response.data[0]
                }
              });
            } else {
              reject(404);
            }
          })
          .catch(err => reject(err));
    });
  },
  saveObservacionProyecto: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      ProyectoService.findObservacionProyecto(data.idProject)
          .then(response => {
            if (response.data.length != 0) {
              ProyectoService.editObservacionProyecto(response.data[0].id, data.observacion)
                  .then(() => {
                    resolve({
                      data: {
                        status: 'ok',
                        response: 'ok'
                      }
                    });
                  })
                  .catch(err => reject(err));
            } else {
              ProyectoService.createObservacionProyecto(data.idProject, data.observacion)
                  .then(() => {
                    resolve({
                      data: {
                        status: 'ok',
                        response: 'ok'
                      }
                    });
                  })
                  .catch(err => reject(err));
            }
          })
          .catch(err => reject(err));
    });
  },
  updateRevisionProyecto: ({commit}, idProject) => {
    commit;
    return new Promise((resolve, reject) => {
      ProyectoService.updateRevisionProyecto(idProject)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getProyectoById: ({commit}, idProject) => {
    commit;
    return new Promise((resolve, reject) => {
      ProyectoService.getProyectoById(idProject)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
    // All approval files with project
    getAllProjectsAndApprovalFileLists: ({commit}, periodo) => {
      commit;
          return new Promise((resolve, reject) => {
              ProyectoService.getAllProjectsAndApprovalFileLists(periodo)
                  .then(response => {
                      resolve({
                          data: {
                              status: 'ok',
                              response: response.data
                          }
                      });
                  })
                  .catch(err => reject(err));
          });
    },
  // Item Gasto
  getTotalItemGasto: ({commit}, ccvalor) => {
    commit;
    return new Promise((resolve, reject) => {
      ItemGastoService.getTotalItemGasto(ccvalor)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getTotalItemGastoProyecto: ({commit}, idProject) => {
    commit;
    return new Promise((resolve, reject) => {
      ItemGastoService.getTotalItemGastoProyecto(idProject)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getItemGastoByProyecto: ({commit}, idProject) => {
    commit;
    return new Promise((resolve, reject) => {
      ItemGastoService.getItemGastoByProyecto(idProject)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  createItemGasto: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      ItemGastoService.createItemGasto(data.id, data.itemGasto)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  deleteItemGasto: ({commit}, idItemGasto) => {
    commit;
    return new Promise((resolve, reject) => {
      ItemGastoService.deleteItemGasto(idItemGasto)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updateItemGasto: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      ItemGastoService.updateItemGasto(data.id, data.itemGasto)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  // Hitos
  getHitoByIdProyecto: ({commit}, idProject) => {
    commit;
    return new Promise((resolve, reject) => {
      HitoService.getHitoByIdProyecto(idProject)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  createHito: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      HitoService.createHito(data.id, data.milestone)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  deleteHito: ({commit}, idHito) => {
    commit;
    return new Promise((resolve, reject) => {
      HitoService.deleteHito(idHito)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updateHito: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      HitoService.updateHito(data.id, data.milestone)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updatePorcentajeAvanceHito: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      HitoService.updatePorcentajeAvanceHito(data.id, data.percent)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updateObservacionesHito: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      HitoService.updateObservacionesHito(data.id, data.observation)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getDocumentoByTipoAndCc: ({commit}, idCentroCosto) => {
    commit;
    return new Promise((resolve, reject) => {
      DocumentoService.getDocumentoByTipoAndCc(idCentroCosto)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  downloadDocumentoProject: ({commit}, idProject) => {
    commit;
    return new Promise((resolve, reject) => {
      DocumentoService.downloadDocumentoProject(idProject)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  uploadDocumentoProject: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      DocumentoService.uploadDocumentoProject(data.idCentroCosto, data.id, data.file)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  uploadDocumentoProjectReiterate: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      ReitemizacionService.uploadDocumentoProjectReiterate(data.idCentroCosto, data.id, data.file)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  downloadReitemizacionById: ({commit}, id) => {
    commit;
    return new Promise((resolve, reject) => {
      ReitemizacionService.downloadReitemizacionById(id)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getAllReitemizacionByProyecto: ({commit}, id) => {
    commit;
    return new Promise((resolve, reject) => {
      ReitemizacionService.getAllReitemizacionByProyecto(id)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  uploadAmountExcelFile: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      MontoProyecto.uploadExcelAdvanceProjectAmount(data.page, data.cols, data.file)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },

  getAllEjeEstrategico: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      EjeEstrategicoService.getAll()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getAllAreaMisional: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      AreaMisionalService.getAll()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getAllIndicadores: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      IndicadorService.getAll()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  createIndicador: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      IndicadorService.createIndicador(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  saveAnnualReport: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      annualReportService.save(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getAllAnnualReports: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      annualReportService.getAll()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getAllAnnualReportsByCostCenter: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      annualReportService.getAllbyCostCenter()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  downloadAnnualReport: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      annualReportService.download(data.id, data.admin)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  createEstrategia: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      estrategiaService.createEstrategia(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getAllEstrategias: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      estrategiaService.getAll()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updateEstrategia: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      estrategiaService.update(data.id, data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  updateIndicador: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      IndicadorService.update(data.id, data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  createPlanTransversal: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      planTransversalService.create(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getAllPlanesTransversales: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      planTransversalService.getAll()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updatePlanTransversal: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      planTransversalService.update(data.id, data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  deleteAnnualReport: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      annualReportService.delete(data.id)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getAllAreasMisionalesByEje: ({commit}, idEje) => {
    commit;
    return new Promise((resolve, reject) => {
      estrategiaService.getAllAreasMisionalesByEje(idEje)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  joinPlanTransversalEjeEstrategico: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      planTransversalService.joinPlanEje(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getAllFilterEjesPlanes: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      planTransversalService.getAllFilterEjesPlanes()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
deleteEjeEstrategicoPlanTranversal: ({commit}, id) => {
    commit;
    return new Promise((resolve, reject) => {
        ejeEstrategicoPlanTranversalService.delete(id)
            .then(response => {
                resolve({
                    data: {
                        status: 'ok',
                        response: response.data
                    }
                });
            })
            .catch(err => reject(err));
    })
},
  getAllEjeEstrategicosByTransversal: ({commit}, id) => {
    commit;
    return new Promise((resolve, reject) => {
      planTransversalService.getAllEjeEstrategicosByTransversal(id)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getAllEstrategiasByEjeAndTransversal: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      estrategiaService.getAllEjeEstrategicosByTransversal(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
    getAllNotFinishedInitiatives: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      initiativeService.getNotFinishedAll()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
    getAllFinishedInitiatives: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            initiativeService.getFinishedAll()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    serviceInitiativeShowFile: ({commit}, id) => {
      commit;
        return new Promise((resolve, reject) => {
            initiativeService.showFile(id)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
  createInitiative: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      initiativeService.create(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updateIniciativa: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      initiativeService.update(data.id, data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  //evaluation main service
  getAllEvaluation: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      evaluationService.getAll()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  createEvaluation: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      evaluationService.create(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updateEvaluation: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      evaluationService.update(data.id, data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  getEvaluationForPeriod: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      evaluationService.getForPeriod(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },

  //question main service
  getAllQuestion: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
      questionService.getAll()
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  createQuestion: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      questionService.create(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updateQuestion: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      questionService.update(data.id, data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
  //Questions Answer
  createQuestionAnswers: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      questionAnswerService.create(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  getAllQuestionsAnswerByProject: ({commit}, data) => {
    commit;
    return new Promise((resolve, reject) => {
      questionAnswerService.getAllByProyecto(data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data
              }
            });
          })
          .catch(err => reject(err));
    })
  },
  updateAnswer: ({ commit }, data) => {
    commit;
    return new Promise((resolve, reject) => {
      questionAnswerService.update(data.id, data)
          .then(response => {
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              }
            });
          })
          .catch(err => reject(err));
    });
  },
    finishProject: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            ProyectoService.finish(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
    uploadFileFinishProject: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            ProyectoService.uploadFile(data.id, data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
    downloadFileFinishProject: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            ProyectoService.downloadFile(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    updateProgress({commit}, data) {
        commit;
            return new Promise((resolve, reject) => {
                initiativeService.updateProgress(data)
                    .then(response => {
                        resolve({
                            data: {
                                status: 'ok',
                                response: response.data
                            }
                        });
                    })
                    .catch(err => reject(err));
            });
    },
    // finish iniciativa
    finishIniciativa: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            initiativeService.finish(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
    // find all cost center
    getAllCostCenter: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            CentroCostosService.getAll()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    serviceCostCenterActivos: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            CentroCostosService.getAllActivo()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    serviceCostCenterMayor: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            CentroCostosService.getAllMayor()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },serviceCostCenterCreate: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            CentroCostosService.create(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },serviceCostCenterUpdate: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            CentroCostosService.edit(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },serviceCostCenterList: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            CentroCostosService.getAll()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },


    // get all strategy

    getAllStrategy: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            StrategyService.getAll()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },

    //create strategy

    createStrategy: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            StrategyService.create(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    updateStrategy: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            StrategyService.update(data.id, data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },


    // administrative bases
    saveAdministrativeBases: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            administrativeBasesService.save(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        });
    },
    getAllAdministrativeBases: ({commit}) => {
    commit;
    return new Promise((resolve, reject) => {
        administrativeBasesService.list()
            .then(response => {
                resolve({
                    data: {
                        status: 'ok',
                        response: response.data
                    }
                });
            })
            .catch(err => {reject(err); console.log(err)});
    });
    },
    showFile: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            administrativeBasesService.showFile()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },

    //period active
    getPeriodActive: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            UserService.getPeriodActive()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    setPeriodActive: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            UserService.setPeriodActive(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },

    // reports
    //advance project amount
    getExcelAdvanceProjectAmount: ({commit}, periodo) => {
        commit;
        return new Promise((resolve, reject) => {
            ReportService.getExcelAdvanceProjectAmount(periodo)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    // excel advance project evaluation
    getExcelAdvanceProjectEvaluation: ({commit}, periodo) => {
        commit;
        return new Promise((resolve, reject) => {
            ReportService.getExcelAdvanceProjectEvaluation(periodo)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    // excel advance project milestone
    getExcelAdvanceProjectMilestones: ({commit}, periodo) => {
        commit;
        return new Promise((resolve, reject) => {
            ReportService.getExcelAdvanceProjectMilestones(periodo)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    // excel project items expenses
    getExcelProjectItemsExpenses: ({commit}, periodo) => {
        commit;
        return new Promise((resolve, reject) => {
            ReportService.getExcelProjectItemsExpenses(periodo)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    // excel project milestone
    getExcelProjectMilestones: ({commit}, periodo) => {
        commit;
        return new Promise((resolve, reject) => {
            ReportService.getExcelProjectMilestones(periodo)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    // excel project ejes
    getExcelProjectEjes: ({commit}, periodo) => {
        commit;
        return new Promise((resolve, reject) => {
            ReportService.getExcelProjectEjes(periodo)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    getExcelApprovalProjectLetters: ({commit}, periodo) => {
        commit;
        return new Promise((resolve, reject) => {
            ReportService.getExcelApprovalProjectLetters(periodo)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    getExcelIniciativas: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            ReportService.getExcelIniciativas()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },

    serviceReportsCostCenterProjectLess: ({commit}, periodo) => {
        commit;
        return new Promise((resolve, reject) => {
            ReportService.getExcelCostCenterProjectLess(periodo)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    serviceAnalystStore: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            AnalystService.store(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    serviceAnalystUpdate: ({commit}, data) => {
        commit;
        return new Promise((resolve, reject) => {
            AnalystService.update(data)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    serviceAnalystList: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            AnalystService.list()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    serviceAnalystListActive: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            AnalystService.listWithActiveTrue()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => {reject(err); console.log(err)});
        });
    },
    downloadAdministrativeBase: ({commit}, fileId) => {
        commit;
        return new Promise((resolve, reject) => {
            administrativeBasesService.showFileById(fileId)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },deleteAdministrativeBase: ({commit}, fileId) => {
        commit;
        return new Promise((resolve, reject) => {
            administrativeBasesService.delete(fileId)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    //Platform forms category
    createPlatformFormsCategory: ({commit}, category) => {
        commit;
        return new Promise((resolve, reject) => {
            PlatformFormsCategoryService.save(category)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    getAllPlatformFormsCategory: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            PlatformFormsCategoryService.list()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    updatePlatformFormsCategory: ({commit}, category) => {
        commit;
        return new Promise((resolve, reject) => {
            PlatformFormsCategoryService.update(category.id, category)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    //Platform Forms
    savePlatformForms: ({commit}, platformForms) => {
        commit;
        return new Promise((resolve, reject) => {
            PlatformFormsService.save(platformForms)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    getAllPlatformForms: ({commit}) => {
        commit;
        return new Promise((resolve, reject) => {
            PlatformFormsService.list()
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    getAllPlatformFormsByCategory: ({commit}, categoryId) => {
        commit;
        return new Promise((resolve, reject) => {
            PlatformFormsService.listByCategory(categoryId)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    showPlatformFormsFile: ({commit}, id) => {
        commit;
        return new Promise((resolve, reject) => {
            PlatformFormsService.showFile(id)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
    deletePlatformForms: ({commit}, id) => {
        commit;
        return new Promise((resolve, reject) => {
            PlatformFormsService.delete(id)
                .then(response => {
                    resolve({
                        data: {
                            status: 'ok',
                            response: response.data
                        }
                    });
                })
                .catch(err => reject(err));
        })
    },
};

const mutations = {};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
