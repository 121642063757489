import AuthService from '@/services/authService';
import jwt_decode from "jwt-decode";

const decodeToken = (token) => {
  const decoded = jwt_decode(token);
  //console.log(decoded);
  return decoded && decoded.ccComplete ? decoded.ccComplete : {};
}
const fullDecodeToken = (token) => {
  const decoded = jwt_decode(token);
  return decoded;
}
const state = {
  authenticated: true,
  accessToken: localStorage.getItem('accessToken') || '',
  info: localStorage.getItem('accessToken') ? decodeToken(localStorage.getItem('accessToken')) : {},
  fullTokenDecoded: localStorage.getItem('accessToken') ? fullDecodeToken(localStorage.getItem('accessToken')) : {}
};
const getters = {
  token: state => {
    return state.accessToken;
  },
  authenticated: state => {
    return state.authenticated;
  },
  info: state => {
    return state.info;
  },
  fullTokenDecoded: state => {
    return state.fullTokenDecoded;
  }
};
const actions = {
  setToken: ({ commit }, token) => {
    return new Promise((resolve) => {
      commit('SET_ACCESS_TOKEN', token);
      commit('SET_AUTH_STATUS', true);
      resolve({
        data: {
          status: 'ok',
          response: token,
        }
      });
    });
  },
  login: ({ commit }, credentials) => {
    commit('CLEAN_LOCAL_STORAGE');
    commit('SET_AUTH_STATUS', false);
    return new Promise((resolve, reject) => {
      AuthService.login(credentials.username, credentials.password)
        .then(response => {
          commit('SET_ACCESS_TOKEN', response.data);
          commit('SET_AUTH_STATUS', true);
          resolve({
            data: {
              status: 'ok',
              response: response.data,
            }
          });
        })
        .catch(err => reject(err));
    });
  },
  logout: ({ commit }) => {
    return new Promise(resolve => {
      commit('CLEAN_LOCAL_STORAGE');
      commit('SET_AUTH_STATUS', false);
      resolve({
        response: {
          status: 'ok',
          data: {
            message: 'Successfully log out'
          }
        },
      });
    });
  },
  validate: ({ commit }, token) => {
    commit('SET_AUTH_STATUS', false);
    return new Promise((resolve, reject) => {
      if (token) {
        AuthService.validate(token)
        .then(response => {
          commit('SET_AUTH_STATUS', true);
          resolve({
            response: {
              status: 'ok',
              data: response.data,
            },
          });
        })
        .catch(err => {
          commit('CLEAN_LOCAL_STORAGE');
          commit('SET_AUTH_STATUS', false);
          reject(err);
        });
      } else {
        reject({
          response: {
            status: 'error',
            data: {
              message: 'No token'
            }
          }
        });
      }
    });
  },
};
const mutations = {
  SET_ACCESS_TOKEN: (state, token) => {
    state.accessToken = token;
    localStorage.setItem('accessToken', token);
    state.info = decodeToken(token);
    state.fullTokenDecoded = fullDecodeToken(token);
  },
  SET_AUTH_STATUS: (state, status) => {
    state.authenticated = status;
  },
  CLEAN_LOCAL_STORAGE: (state) => {
    localStorage.removeItem('accessToken');
    state.accessToken = '';
    state.info = {};
    state.fullTokenDecoded = {};
  },
};
export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
