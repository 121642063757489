import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;
const PREFIX = 'monto-proyecto/'
export default {
    uploadExcelAdvanceProjectAmount: (page, cols, file) => {
        var bodyFormData = new FormData();
        bodyFormData.append('file', file);
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX + 'upload-informe-avance-montos-solicitados' + '?hoja=' + page + '&columna=' + cols,
                method: 'POST',
                data: bodyFormData,
                // responseType: 'blob', // Important
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
}