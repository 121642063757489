import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    save: (platformForms)=>{
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('name', platformForms.name);
            bodyFormData.append('category', platformForms.category);
            bodyFormData.append('file', platformForms.file);
            bodyFormData.append('pages', platformForms.pages);
            auth
                .post(BASE_URL + 'formularios-plataforma', bodyFormData )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    list: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'formularios-plataforma')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    listByCategory: (categoryId) => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'formularios-plataforma/categoria/' + categoryId)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    update: (id, platformForms) => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'formularios-plataforma/' + id, platformForms)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    showFile: (id) => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'formularios-plataforma/archivo/' + id)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    delete: (id) => {
        return new Promise((resolve, reject) => {
            auth
                .delete(BASE_URL + 'formularios-plataforma/' + id)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
}