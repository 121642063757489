import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

import es from 'vuetify/es5/locale/es';

const vuetify = new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        // Background color
        'main-color': '#F9FAFB',
        // Background when login color;
        'main-app-color': '#E5E7EB',
        // Default text color
        'main-text': '#1E293B',
        // Color palette
        'primary-color': '#EA7600',
        'primary-text': '#FFFFFF',
        'secondary-color': '#394049',
        'tertiary-color': '#7d8e98',
        'quaternary-color': '#d5dadc',
        // Links
        'link-text': '#0F172A',
        // Loading images
        'loading-color': '#CBD5E1',
      },
    },
  },
})

export default vuetify;