import axios from 'axios';
//import router from '@/router';
import store from '@/store';

const authAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL_BASE
});

authAxios.interceptors.request.use(
  config => {
    let token = store.getters['authentication/token'];
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => Promise.reject(error)
);

authAxios.interceptors.response.use(
  response => response,
  error => {
    // second validation that sends to login
    // if(error.response.status === 401) {
    //   if (window.location.pathname !== '/login') {
    //     //router.push('/login');
    //     return;
    //   }
    // }
    return Promise.reject(error);
  }
);

export default authAxios;
