import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    save: (data)=>{
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('periodo', data.periodo);
            bodyFormData.append('archivo', data.archivo);
            bodyFormData.append('centroCosto', data.centroCosto);
            auth.post(BASE_URL + 'reporte-anual', bodyFormData )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getAll: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'reporte-anual')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getAllbyCostCenter: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'reporte-anual/listado-ccosto')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    download: (id, admin) => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'reporte-anual/'+id+'/download' + ((admin)?'-any':''))
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    delete: (id) => {
        return new Promise((resolve, reject) => {
            auth
                .delete(BASE_URL + 'reporte-anual/' + id)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
}