import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  createProyecto: (project) => {
    // console.error(project)
    return new Promise((resolve, reject) => {
      auth
        .post(BASE_URL + 'proyecto/create?ccId=' + project.ccId + '&idEstrategias=' + project.idEstrategias + '&idUnidadesAliadas='+project.idUnidadesAliadas, project.form)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  updateProyecto: (project) => {
    return new Promise((resolve, reject) => {
      auth
        .put(BASE_URL + 'proyecto/update?ccId='+project.ccId + '&idEstrategias='+project.idEstrategias + '&idUnidadesAliadas='+ project.idUnidadesAliadas, project.form)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  getAll: (ccValor) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'proyecto/getAllByCCValor?ccValor=' + ccValor)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  getAllProjectsAmountRequest: () => {
    return new Promise((resolve, reject) => {
      auth
          .get(BASE_URL + 'proyecto/amount-request')
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
  getProyectoById: (idProject) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'proyecto/getById?id=' + idProject)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  updateRevisionProyecto: (idProject) => {
    return new Promise((resolve, reject) => {
      auth
        .put(BASE_URL + 'proyecto/updateRevision?id=' + idProject)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  findObservacionProyecto: (idProject) => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'observacionProyecto/getByIdProyecto?idProyecto=' + idProject)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  editObservacionProyecto: (id, observacion) => {
    return new Promise((resolve, reject) => {
      auth
        .put(BASE_URL + 'observacionProyecto/update', {
          id: id,
          descripcion: observacion
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  createObservacionProyecto: (idProject, observacion) => {
    return new Promise((resolve, reject) => {
      auth
        .post(BASE_URL + 'observacionProyecto/create?idProyecto='+idProject, {
          descripcion: observacion
        })
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },

  uploadFile: (id, data)=>{
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData();
      bodyFormData.append('file', data.file);
      auth.put(BASE_URL + 'proyecto/' + id + '/upload-file-finish-project', bodyFormData )
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
  downloadFile: (id) => {
    return new Promise((resolve, reject) => {
      auth
          .get(BASE_URL + 'proyecto/' + id + '/download-file-finish-project')
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
  finish: (id)=>{
    return new Promise((resolve, reject) => {
      auth.post(BASE_URL + 'proyecto/' + id + '/finish-project', {} )
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
  getAllProjectsAndApprovalFileLists: (periodo)=>{
    return new Promise((resolve, reject) => {
      auth
          .get(BASE_URL + 'proyecto/' + periodo + '/approval-file-list')
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
  delete: (id)=>{
    return new Promise((resolve, reject) => {
      auth.delete(BASE_URL + 'proyecto/' + id, {} )
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
};