import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    save: (category)=>{
        return new Promise((resolve, reject) => {
            auth
                .post(BASE_URL + 'categorias-formularios-plataforma', category )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    list: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'categorias-formularios-plataforma')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    update: (id, category) => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'categorias-formularios-plataforma/' + id, category)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
}