import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;
const PREFIX = 'reporte/'
export default {
    getExcelAdvanceProjectAmount: (periodo) => {
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX + periodo + '/informe-avance-montos-solicitados',
                method: 'GET'
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getExcelAdvanceProjectEvaluation: (periodo) => {
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX + periodo + '/informe-avance-evaluacion-proyectos',
                method: 'GET',
                // responseType: 'blob', // Important
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getExcelAdvanceProjectMilestones: (periodo) => {
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX + periodo + '/informe-avance-hitos-proyectos',
                method: 'GET',
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getExcelProjectItemsExpenses: (periodo) => {
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX + periodo + '/ficha-items-gastos-proyectos',
                method: 'GET'
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getExcelProjectMilestones: (periodo) => {
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX + periodo + '/ficha-hitos-proyectos',
                method: 'GET',
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getExcelProjectEjes: (periodo) => {
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX + periodo + '/ficha-ejes-proyectos',
                method: 'GET'
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },

    getExcelApprovalProjectLetters: (periodo) => {
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX + periodo + '/ficha-avance-aprobacion-carta-proyectos',
                method: 'GET'
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },

    getExcelIniciativas: () => {
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX  + 'ficha-iniciativas',
                method: 'GET'
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getExcelCostCenterProjectLess: (periodo) => {
        return new Promise((resolve, reject) => {
            auth({
                url: BASE_URL + PREFIX  + periodo + '/ficha-proyectos-centrocosto',
                method: 'GET'
            })
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    }
}