import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  getAllByIdCentroCosto: id => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'ejeEstrategico/getAllByIdCentroCosto?idCentroCosto=' + id)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  getAll: () => {
    return new Promise((resolve, reject) => {
      auth
          .get(BASE_URL + 'ejesEstrategicos/')
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
};
