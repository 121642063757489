import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
    store: (data)=>{
        return new Promise((resolve, reject) => {
            auth.post(BASE_URL + 'analistas', data )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    update: (data)=>{
        return new Promise((resolve, reject) => {
            auth.put(BASE_URL + 'analistas/' + data.id, data )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    list: () => {
        return new Promise((resolve, reject) => {
            auth.get(BASE_URL + 'analistas')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    listWithActiveTrue() {
        return new Promise((resolve, reject) => {
            auth.get(BASE_URL + 'analistas/active')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    }
}