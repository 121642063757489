import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  getAllByIdEjeEstrategico: id => {
    return new Promise((resolve, reject) => {
      auth
        .get(BASE_URL + 'areaMisional/getAllByIdEjeEstrategico?idEje=' + id)
        .then(response => (resolve(response)))
        .catch(error => (reject(error)));
    });
  },
  getAll: () => {
    return new Promise((resolve, reject) => {
      auth
          .get(BASE_URL + 'areasMisionales')
          .then(response => (resolve(response)))
          .catch(error => (reject(error)));
    });
  },
};
