import auth from '@/utils/auth';

const BASE_URL = process.env.VUE_APP_API_URL_BASE;

export default {
  create: (planTransversal) => {
      return new Promise((resolve, reject) => {
          auth
              .post(BASE_URL + 'plan-transversal', planTransversal)
              .then(response => (resolve(response)))
              .catch(error => (reject(error)));
      });
  },
    getAll: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'plan-transversal')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    update: (id,data) => {
        return new Promise((resolve, reject) => {
            auth
                .put(BASE_URL + 'plan-transversal/' + id,
                    data
                )
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    joinPlanEje: (planTransversal) => {
        return new Promise((resolve, reject) => {
            auth
                .post(BASE_URL + 'transversal-eje-estrategico', planTransversal)
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getAllFilterEjesPlanes: () => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'transversal-eje-estrategico')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
    getAllEjeEstrategicosByTransversal: (id) => {
        return new Promise((resolve, reject) => {
            auth
                .get(BASE_URL + 'plan-transversal/' + id + '/ejes-estrategicos')
                .then(response => (resolve(response)))
                .catch(error => (reject(error)));
        });
    },
};
